import React from "react";

import { Link } from "react-router-dom";

// images
import logo from "../../assets/images/logo.png";
import grm from "../../assets/images/grm.png";
import aldealista from "../../assets/images/aldealista.png";
import background_image_1 from "../../assets/images/patterns/pattern-1.jpg";
import { useTranslation } from "react-i18next";
import "../../languages/i18n.js";

const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <footer className="footer">
      <div className="footer-wrapper">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6"
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <div className="footer-row">
                <div className="footer-detail">
                  <Link to="#">
                    <img src={logo} alt="footer-logo" />
                  </Link>

                  <p className="c-grey-1">{t("page.title")}</p>
                  <div className="links">
                    {t("page.title")} &copy; {t("footer.copyright")}
                  </div>
                </div>
                <div className="footer-list footer-social social-gradient"></div>
                <div className="footer-list"></div>
                <div className="footer-list"></div>
              </div>
            </div>
            <div
              className="col-lg-6"
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2945.7818153507483!2d-7.338918184542073!3d42.41109087918347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDI0JzM5LjkiTiA3wrAyMCcxMi4yIlc!5e0!3m2!1sen!2sno!4v1679426365405!5m2!1sen!2sno"
                width="199%"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>

        <div className="container" style={{ marginTop: "50px" }}>
          <div className="row">
            <div
              className="col-lg-6"
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <div className="footer-row">
                <div className="footer-detail" style={{ textAlign: "center" }}>
                  <a href="https://aldealista.com">
                    <img
                      src={aldealista}
                      alt="footer-logo"
                      style={{ width: "125px" }}
                    />
                  </a>
                  <p className="c-grey-1">Aldealista</p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6"
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <div className="footer-row">
                <div className="footer-detail" style={{ textAlign: "center" }}>
                  <a href="https://gruporuralmanager.com">
                    <img
                      src={grm}
                      alt="footer-logo"
                      style={{ width: "125px" }}
                    />
                  </a>
                  <p className="c-grey-1">Grupo Rural Manager</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="footer-pattern"
          style={{ backgroundImage: `url(${background_image_1})` }}
        ></div>
      </div>
    </footer>
  );
};

export default Footer;
